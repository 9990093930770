import * as React from "react";
import styled from 'styled-components';
import imgSrc from '../img/error_img.jpg';
import SiteWrapper from '../components/siteWrapper';
import { BigText } from '../components/common';

const Header = styled.div`
    width: 100%;
`
const HeaderImg = styled.img`
    width: 100%;
    height: auto;
`
const Content = styled.div`
  padding: 20px;
`

const NotFoundPage = () => (
  <SiteWrapper title="Error 404 - Seite nicht gefunden.">
      <Header>
          <HeaderImg src={imgSrc} alt="Headerbild" />
      </Header>
      <Content>
        <BigText>Error 404 - Die Seite wurde leider nicht gefunden.</BigText>
      </Content>
  </SiteWrapper>
)

export default NotFoundPage
